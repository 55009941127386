import type { ProductBadge } from 'types/models/product'
import type { CartItemOption } from './cart'
import type { Environment } from './environment'

export interface Datalayer {
  push: (data: GTMEvent) => number
}

export interface Product {
  id: string // currently sku for gtm -> This should be changed to barcode
  barcode: string
  name: string
  price: number
  original_price: number
  priceEur?: number
  list?: string
  category?: string
  quantity?: number
  position?: number
  variant?: CartItemOption | string
  dimension12?: string // In/Out of stock we cannot change label
  metric1?: number | string // % value of discount without `%` sign
  collectionContent?: boolean
  collectionIndex?: number
  variantId?: string // !Deprecate!
  parentSku: string
  badges?: ProductBadge[]
  sku: string
}
export interface ProductVariant {
  id: string // This is the parent / configurable product id
  variantId: string
  barcode: string // ean, also used as id in Brink
  name: string
  price: number
  original_price: number
  priceEur: number
  quantity: number
  variant: CartItemOption | string
  dimension12?: string // In/Out of stock we cannot change label
  parentSku: string
  sku: string
}

export interface ProcessedProduct extends Product {
  affiliation?: string
  currency?: string
}
export interface GeneralInfoEvent {
  event: 'general'
  language: Environment.Language
  locale: string
  customerId: string
  countryCode: Environment.CountryCode
  pageType: string
  pageUrl: string
  splitTests: {
    [key: string]: string
  }
  ecommerce: {
    cart: {
      products: ProcessedProduct[]
    }
  }
}

export enum ProductPageEvents {
  sizeSelection = 'sizeProductPage',
  similarProduct = 'similarProductPage',
  informationTabs = 'tabsProductPage',
}
export interface ProductPageEvent {
  event: ProductPageEvents
  countryCode: Environment.CountryCode
  eventInfo: {
    category: string
    action: string
    label: string
    sku?: string
  }
}

export interface CloseProductPageEvent {
  event: 'closeProduct'
  countryCode: Environment.CountryCode
  eventInfo: {
    category: string
    action: string
  }
  pageUrl: string
}

export interface RemoveFromCartEvent {
  event: 'removeFromCart'
  countryCode: Environment.CountryCode
  ecommerce: {
    currencyCode: Environment.Currency['code']
    remove: {
      products: Product[]
    }
  }
}

export type AddToCartSources = 'productPage' | 'cartPageUpsell' | 'wishlistPage' | 'productPageUpsell' | 'cartPage' | 'searchPage' | 'homePage' | 'categoryPage' | 'otherPage'
export interface AddToCartEvent {
  event: 'addToCart'
  customerId: string
  countryCode: Environment.CountryCode
  ecommerce: {
    currencyCode: string
    add: {
      products: Product[]
    }
  }
  addToCartSource: AddToCartSources
  isBwp: boolean
}

export interface UpdateCartEvent {
  event: 'cart'
  countryCode: Environment.CountryCode
  ecommerce: {
    cart: {
      total: number
      totalEur: number
      products: Product[]
    }
  }
}

export interface ProductClickEvent {
  event: 'productClick'
  countryCode: Environment.CountryCode
  widgetId: string | null
  sourceId?: string
  ecommerce: {
    click: {
      actionField: {
        action: string
      }
      products: Product[]
    }
  }
}

export interface ProductDetailEvent {
  event: 'productDetail'
  customerId: string
  countryCode: Environment.CountryCode
  ecommerce: {
    currencyCode: string
    detail: {
      products: Product[]
    }
  }
}

export interface ProductImpressionEvent {
  event: 'productImpression'
  countryCode: Environment.CountryCode
  ecommerce: {
    currencyCode: string
    impressions: Product[]
  }
}

export type SignupSources = 'popup' | 'page' | 'footer' | 'menu' | 'checkout' | 'other'
export interface NewsletterSignupEvent {
  event: 'newsletterSignup'
  countryCode: Environment.CountryCode
  eventInfo: {
    category: string
    action: string
    label: string
    email: string
    signupSource: SignupSources
    fields: Record<string, any>
    identifier?: string
  }
}

export enum PromotionEvents {
  promotionView = 'promoView',
  promotionClick = 'promoClick',
}
export interface PromotionEvent {
  event: keyof typeof PromotionEvents
  countryCode: Environment.CountryCode
  ecommerce: Partial<
    Record<
      `${PromotionEvents}`,
      {
        promotions: [
          {
            id: string
            name: string
            position: number
            creative: string
          },
        ]
      }
    >
  >
}

export interface CheckoutEvent {
  event: 'checkout'
  countryCode: Environment.CountryCode
  ecommerce: {
    checkout: {
      actionField: {
        step: number
      }
    }
  }
}

export interface CheckoutCustomerEmailUpdateEvent {
  event: 'checkout-customer-email-update'
  countryCode: Environment.CountryCode
  customerEmail: string // Used for Emarsys purchase tracking
}

export interface PurchaseEvent {
  event: 'purchase'
  pageType: string
  countryCode: Environment.CountryCode
  language: Environment.Language
  customerId: string
  customerEmail: string // Used for Emarsys purchase tracking
  pageUrl: string | null
  paymentMethod: string
  ecommerce: {
    purchase: {
      actionField: {
        id: string // transaction id
        revenue: number
        revenueEur: number
        tax: number
        shipping: number
        paymentOption: string
        affiliation?: string
        discountCode: string
      }
      products: Product[]
      hasCompleted: boolean
    }
    currencyCode: string
  }
}

export interface ProductPurchaseEvent {
  event: 'productPurchase'
  countryCode: Environment.CountryCode
  customerId: string
  pageUrl: string | null
  ecommerce: {
    productPurchase: {
      product: Product
    }
  }
}

export interface PaymentCancelEvent {
  event: 'paymentCancel'
  countryCode: Environment.CountryCode
  customerId: string
}

export interface OrderCompleteEvent {
  event: 'orderComplete'
  countryCode: Environment.CountryCode
  orderValue: number
  orderCurrency: string
  orderId: string
  paymentMethod: string
  ecData: {
    email: string
    phoneNumber: string
    firstName: string
    lastName: string
    homeAddress: {
      street: string
      city: string
      region: string
      postalCode: string
      country: string
    }
  }
}

export interface OrderDiscountCodeEvent {
  event: 'orderDiscountCode'
  countryCode: Environment.CountryCode
  discountCode: string
  ecommerce: {
    products: Product[]
  }
}

export interface ClickSearchSuggestionsEvent {
  event: 'clickSearchSuggestions'
  sourceId?: string
  countryCode: Environment.CountryCode
  ecommerce: {
    query: string
    sku: string
    action: string
  }
}

export interface PageBlockClickEvent {
  event: 'pageBlockClick'
  countryCode: Environment.CountryCode
  clickType: 'image' | 'button' | 'product' | string
  clickBlockPosition: number
  clickURL: string
  pageUrl: string
  routeName: string
  routeType: string
}
export type FilterClickSource = 'sidebar' | 'quickFilter'
export interface FilterClickEvent {
  event: 'filterClick'
  countryCode: Environment.CountryCode
  filterId: string
  filterValue: string[]
  activeFilters: { id: string, values: string[] }[]
  sourceId?: string
  clickSource: FilterClickSource
}

export interface SpaPageViewEvent {
  pageType: 'PageView'
  countryCode: Environment.CountryCode
  sourceId?: string
  pageUrl: string
  event: 'SPA-PageView'
  routeName: string
  routeType: string
  segmentIdentifier?: string
}

export interface PopupEvent {
  event: 'popup'
  countryCode: Environment.CountryCode
  popupIdentifier: string
}

export type GTMEvent =
  | GeneralInfoEvent
  | ProductPageEvent
  | CloseProductPageEvent
  | RemoveFromCartEvent
  | AddToCartEvent
  | UpdateCartEvent
  | ProductClickEvent
  | ProductDetailEvent
  | ProductImpressionEvent
  | NewsletterSignupEvent
  | PromotionEvent
  | CheckoutEvent
  | PurchaseEvent
  | ProductPurchaseEvent
  | PaymentCancelEvent
  | OrderCompleteEvent
  | OrderDiscountCodeEvent
  | ClickSearchSuggestionsEvent
  | PageBlockClickEvent
  | FilterClickEvent
  | SpaPageViewEvent
  | PopupEvent
  | { event: string }
